<template>
    <div class="sidebar do-not-print-me">
        <!-- <div style="height: 90vh;overflow-y: auto;position: sticky;top:5.5em"> -->
        <SidebarItem
            pageName="Dashboard"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/dashboard.png`)"
            :pageIconStyle="{ height: '25px', width: '25px' }"
            menuText="Dashboard"
            :showBottomBorder="true"
            :isSidebarExpanded="isSidebarExpanded"
        />

        <SubmenuSidebarItem
            pageName="Company"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/company.png`)"
            :pageIconStyle="{ height: '25px', width: '25px' }"
            menuText="Company"
            :showBottomBorder="true"
            :isSidebarExpanded="isSidebarExpanded"
            :submenuLinks="companyLinks"
            @accordionOpen="handleClick"
            v-if="companyLinks.length"
        />

        <SubmenuSidebarItem
            pageName="Users"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/users.png`)"
            :pageIconStyle="{ height: '25px', width: '25px' }"
            menuText="Users"
            :showBottomBorder="true"
            :isSidebarExpanded="isSidebarExpanded"
            :submenuLinks="userLinks"
            @accordionOpen="handleClick"
            v-if="userLinks.length"
        />

        <SubmenuSidebarItem
            pageName="Clients"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/members.png`)"
            :pageIconStyle="{ height: '25px', width: '25px' }"
            menuText="Members"
            :showBottomBorder="true"
            :isSidebarExpanded="isSidebarExpanded"
            :submenuLinks="clientsList"
            @accordionOpen="handleClick"
            v-if="clientLinks.length"
        />

        <SubmenuSidebarItem
            pageName="Scheduling"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/scheduling.png`)"
            :pageIconStyle="{ height: '25px', width: '25px' }"
            menuText="Scheduling"
            :showBottomBorder="true"
            :isSidebarExpanded="isSidebarExpanded"
            :submenuLinks="schedulingLinks"
            @accordionOpen="handleClick"
            v-if="schedulingLinks.length"
        />

        <!-- <SubmenuSidebarItem
                pageName="Email"
                pageIcon="email"
                menuText="Email"
                :submenuLinks="emailLinks"
                @accordionOpen="handleClick"
                v-if="emailLinks.length"
            /> -->

        <!-- <SubmenuSidebarItem
                pageName="Billing"
                pageIcon="request_quote"
                menuText="Billing"
                :submenuLinks="billingLinks"
                @accordionOpen="handleClick"
                v-if="billingLinks.length"
            /> -->

        <SidebarItem
            pageName="VideoConferences"
            :pageIcon="require(`@/assets/px/sidebar-icons-images/video_conferences.png`)"
            :pageIconStyle="{ height: '23px', width: '33px' }"
            menuText="Telehealth"
            :showBottomBorder="false"
            :isSidebarExpanded="isSidebarExpanded"
        />

        <!-- <SubmenuSidebarItem
                pageName="Reports"
                :pageIcon="require(`@/assets/px/sidebar-icons-images/report.png`)"
                :pageIconStyle="{height: '25px',width: '25px'}"
                menuText="Reports"
                :showBottomBorder="false"
                :isSidebarExpanded="isSidebarExpanded"
                :submenuLinks="reportsLinks"
                @accordionOpen="handleClick"
                v-if="reportsLinks.length"
            /> -->

        <SubmenuSidebarItem
            pageName="Dev"
            pageIcon="code"
            menuText="Dev Tools"
            :showBottomBorder="false"
            :isSidebarExpanded="isSidebarExpanded"
            :submenuLinks="dev_links"
            @accordionOpen="handleClick"
            v-if="aws_env && dev_links.length"
        />
        <!-- </div> -->
    </div>
</template>

<script>
    // class="router-link-exact-active router-link-active" aria-current="page"
    import { mapGetters } from 'vuex';
    import SubmenuSidebarItem from '@/components/layout/SubmenuSidebarItem';
    import SidebarItem from '@/components/layout/SidebarItem';
    import { users } from '@/util/apiRequests';

    export default {
        name: 'Sidebar',
        components: { SidebarItem, SubmenuSidebarItem },
        props: {
            isSidebarExpanded: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                currentlyOpened: '',
                companyLinks: [],
                userLinks: [],
                clientLinks: [],
                schedulingLinks: [],
                emailLinks: [],
                //billingLinks: [],
                reportsLinks: [],
                dev_links: [],
                aws_env: 0,
            };
        },
        computed: {
            ...mapGetters(['menuPages']),
            isMainCompany() {
                return +process.env.VUE_APP_mobex_plus_company_id === this.$store.state.user.company_id;
            },
            clientsList() {
                return this.clientLinks.filter((item) => item.pageName !== 'UnapprovedClients' || this.isMainCompany);
            },
        },
        methods: {
            getRoute(name, componentName) {
                if (name === 'users/new') {
                    return { name: 'UserDetail', params: { userId: 'new' } };
                }

                return { name: componentName };
            },
            handleClick(e) {
                if (e.target.id === this.currentlyOpened) {
                    e.target.checked = false;
                    this.currentlyOpened = '';
                } else {
                    this.currentlyOpened = e.target.id;
                }

                if (this.currentlyOpened) {
                    this.$emit('expandSidebar');
                }
            },
            closeAllPanels() {
                const openAccordion = document.getElementById(this.currentlyOpened);

                if (openAccordion) {
                    openAccordion.checked = false;
                    this.currentlyOpened = '';
                }
            },
            async getAvailableMenuItems() {
                const res = await this.$api.get(users.menu());

                if (res.status === 200) {
                    this.companyLinks = res.data.company;
                    this.userLinks = res.data.users;
                    this.clientLinks = res.data.clients;
                    this.schedulingLinks = res.data.scheduling;
                    this.emailLinks = res.data.email;
                    this.billingLinks = res.data.billing;
                    this.reportsLinks = res.data.reports;
                    if (res.data.dev) {
                        this.dev_links = res.data.dev;
                    }
                }
            },
        },
        created() {
            if (process.env.VUE_APP_AWS_ENV == 'dev' || process.env.VUE_APP_AWS_ENV == 'stag') {
                this.aws_env = 1;
            }
            this.getAvailableMenuItems();
        },
    };
</script>
